import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";

import css from "./LandingPage.module.scss";

export const IconParking = () => {
  return (
    <svg
      className={css.amenityIcon}
      height="34"
      width="34"
      viewBox="0 0 128 128"
      aria-hidden="true"
    >
      <path d="M70.8 44H58v16h12.8a8 8 0 0 0 0-16z" />
      <path d="M108 8H20A12 12 0 0 0 8 20v88a12 12 0 0 0 12 12h88a12 12 0 0 0 12-12V20a12 12 0 0 0-12-12zM70 76H58v24H42V28h28a24 24 0 0 1 0 48z" />
    </svg>
  );
};

export const IconWiFi = () => {
  return (
    <svg
      className={css.amenityIcon}
      height="34"
      width="34"
      viewBox="0 0 128 128"
    >
      <circle cx={64} cy={100} r={12} />
      <path d="M118.3 32.7A94.9 94.9 0 0 0 64 16 94.9 94.9 0 0 0 9.7 32.7a4 4 0 1 0 4.6 6.6A87 87 0 0 1 64 24a87 87 0 0 1 49.7 15.3 4 4 0 1 0 4.6-6.6zM87.7 68.4a54.9 54.9 0 0 0-47.4 0 4 4 0 0 0 3.4 7.2 47 47 0 0 1 40.6 0 4 4 0 0 0 3.4-7.2z" />
      <path d="M104 50.5a81.2 81.2 0 0 0-80 0 4 4 0 0 0 4 7 73.2 73.2 0 0 1 72 0 4 4 0 0 0 4-7z" />
    </svg>
  );
};

export const IconBus = () => {
  return (
    <svg
      className={css.amenityIcon}
      height="34"
      width="34"
      viewBox="0 0 128 128"
    >
      <path d="M92 100a10 10 0 1 1-10 10 10 10 0 0 1 10-10zm-66 10a10 10 0 1 0 10-10 10 10 0 0 0-10 10zM16 56h88.2a8 8 0 0 1 7.6 5.5l7.8 26.3a8 8 0 0 1 .4 2.5V106a6 6 0 0 1-6 6h-6.1a16 16 0 1 0-31.8 0H52a16 16 0 1 0-31.8 0H16a8 8 0 0 1-8-8V64a8 8 0 0 1 8-8zm72 24 25 8-7-24H88zm-24 0h16V64H64zm-24 0h16V64H40zm-24 0h16V64H16zm28.2-44.6 8 4.5 4.4 8a.4.4 0 0 0 .6 0l1-1a.4.4 0 0 0 0-.3V37l6.5-5.9L76.1 46a1.4 1.4 0 0 0 2 .4l1-.5a1.4 1.4 0 0 0 .5-1.8L72 24.2l9-8.4a10.2 10.2 0 0 0 3-6.4A1.4 1.4 0 0 0 82.6 8a10.2 10.2 0 0 0-6.5 2.9L67.6 20l-19.8-7.5a1.4 1.4 0 0 0-1.8.6l-.5 1A1.4 1.4 0 0 0 46 16l15 11.5-5.8 6.2h-9.7a.4.4 0 0 0-.3.1l-1 1a.4.4 0 0 0 0 .6z" />
    </svg>
  );
};

export const IconAirconditioner = () => {
  return (
    <svg
      className={css.amenityIcon}
      xmlns="http://www.w3.org/2000/svg"
      width={34}
      height={34}
      viewBox="0 0 455 455"
    >
      <g xmlns="http://www.w3.org/2000/svg">
        <path
          d="M425.17 67.167H29.83C13.382 67.167 0 80.549 0 96.997v152.67a7.5 7.5 0 0 0 7.5 7.5h440a7.5 7.5 0 0 0 7.5-7.5V96.997c0-16.448-13.382-29.83-29.83-29.83zm-392.67 15h390v9.51c0 4.957-4.033 8.99-8.99 8.99H41.49c-4.957 0-8.99-4.033-8.99-8.99v-9.51zm390 122.5h-390v-1.01c0-4.957 4.033-8.99 8.99-8.99h372.02c4.957 0 8.99 4.033 8.99 8.99v1.01zm-390 10h390v10h-390v-10zm0 20h390v7.5h-390v-7.5zm407.5 7.5h-7.5v-38.51c0-10.471-8.519-18.99-18.99-18.99H41.49c-10.472 0-18.99 8.519-18.99 18.99v38.51H15V96.997c0-5.51 3.027-10.319 7.5-12.875v7.555c0 10.471 8.519 18.99 18.99 18.99h372.02c10.472 0 18.99-8.519 18.99-18.99v-7.555c4.473 2.556 7.5 7.365 7.5 12.875v145.17z"
          data-original="#000000"
        />
        <path
          d="M250.5 164.667h-46a5 5 0 1 0 0 10h46a5 5 0 1 0 0-10zM401.503 164.668c-1.32 0-2.61.53-3.54 1.46s-1.46 2.22-1.46 3.54.53 2.61 1.46 3.53c.93.94 2.22 1.47 3.54 1.47 1.31 0 2.6-.53 3.53-1.47.93-.93 1.47-2.22 1.47-3.53s-.54-2.6-1.47-3.54a5.03 5.03 0 0 0-3.53-1.46zM378.503 164.668c-1.32 0-2.61.53-3.54 1.46s-1.46 2.22-1.46 3.54.53 2.6 1.46 3.53c.93.93 2.22 1.47 3.54 1.47 1.31 0 2.6-.54 3.53-1.47a5.04 5.04 0 0 0 1.47-3.53c0-1.32-.54-2.61-1.47-3.54a5.03 5.03 0 0 0-3.53-1.46zM186.319 289.942a5 5 0 1 0-8.723-4.892c-9.09 16.21-9.091 36.35-.001 52.561 7.396 13.192 7.396 29.583 0 42.776a5 5 0 0 0 8.722 4.891c9.088-16.209 9.088-36.348 0-52.557-7.397-13.195-7.396-29.586.002-42.779zM231.862 289.942a5 5 0 1 0-8.723-4.892c-9.09 16.21-9.091 36.35-.001 52.561 7.396 13.192 7.396 29.583 0 42.776a5 5 0 0 0 8.722 4.891c9.088-16.209 9.088-36.348 0-52.557-7.397-13.195-7.396-29.586.002-42.779zM277.406 289.942a5 5 0 1 0-8.723-4.892c-9.091 16.21-9.091 36.35-.002 52.561 7.397 13.193 7.397 29.583 0 42.776a5 5 0 0 0 8.722 4.891c9.089-16.209 9.089-36.348 0-52.557-7.396-13.194-7.396-29.586.003-42.779z"
          data-original="#000000"
        />
      </g>
    </svg>
  );
};

export const IconCash = () => {
  return (
    <svg className={css.amenityIcon} height="34" width="34" viewBox="0 0 24 24">
      <path d="M21 18.5V20a2.25 2.25 0 0 1-2.25 2.25h-15A2.25 2.25 0 0 1 1.5 20V9.5a2.25 2.25 0 0 1 2.25-2.25h15A2.25 2.25 0 0 1 21 9.5V11a.75.75 0 0 0 1.5 0V9.5a3.75 3.75 0 0 0-3.75-3.75h-15A3.75 3.75 0 0 0 0 9.5V20a3.75 3.75 0 0 0 3.75 3.75h15A3.75 3.75 0 0 0 22.5 20v-1.5a.75.75 0 0 0-1.5 0zM19.42 6.165l-2.328-4.658A2.25 2.25 0 0 0 14.074.5h-.002L3.415 5.83a.75.75 0 0 0 .67 1.34l10.658-5.328a.75.75 0 0 1 1.007.335l2.33 4.658a.75.75 0 1 0 1.34-.67zm2.33 13.085A2.25 2.25 0 0 0 24 17v-4.5a2.25 2.25 0 0 0-2.25-2.25H18a4.5 4.5 0 1 0 0 9h3.75zm0-1.5H18a3 3 0 1 1 0-6h3.75a.75.75 0 0 1 .75.75V17a.75.75 0 0 1-.75.75zM18 15.125a.375.375 0 1 1 0-.75.375.375 0 0 1 0 .75.75.75 0 0 0 0-1.5 1.125 1.125 0 1 0 0 2.25 1.125 1.125 0 0 0 0-2.25.75.75 0 0 0 0 1.5z" />
    </svg>
  );
};

export const IconReserve = () => {
  return (
    <svg className={css.amenityIcon} height="34" width="34" viewBox="0 0 24 24">
      <path d="M18 16.5a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0zm1.5 0a2.25 2.25 0 1 0-4.5 0 2.25 2.25 0 0 0 4.5 0zm-1.694-5.582.442 1.453a1.744 1.744 0 0 0 2.06 1.194l1.473-.341a.588.588 0 0 1 .557.976l-1.032 1.112a1.75 1.75 0 0 0 0 2.383l1.031 1.11a.589.589 0 0 1-.56.976l-1.468-.34a1.744 1.744 0 0 0-2.06 1.195l-.442 1.445a.58.58 0 0 1-1.113.004l-.442-1.456a1.744 1.744 0 0 0-2.06-1.194l-1.473.341a.588.588 0 0 1-.557-.976l1.032-1.112a1.75 1.75 0 0 0 0-2.383l-1.031-1.11a.589.589 0 0 1 .56-.976l1.468.34a1.744 1.744 0 0 0 2.06-1.195l.442-1.452a.58.58 0 0 1 1.112.002zm1.437-.432a2.08 2.08 0 0 0-3.985-.009l-.441 1.452a.244.244 0 0 1-.29.168l-1.47-.34a2.089 2.089 0 0 0-1.994 3.46l1.031 1.11a.25.25 0 0 1 0 .34l-1.03 1.112a2.089 2.089 0 0 0 1.989 3.46l1.476-.342a.244.244 0 0 1 .287.167l.442 1.454a2.079 2.079 0 0 0 3.984-.003l.441-1.443a.244.244 0 0 1 .29-.169l1.47.34a2.089 2.089 0 0 0 1.994-3.46l-1.031-1.11a.25.25 0 0 1 0-.34l1.03-1.112a2.089 2.089 0 0 0-1.989-3.46l-1.476.342a.244.244 0 0 1-.287-.167l-.443-1.454zM3.75 7.5h10.5a.75.75 0 0 0 0-1.5H3.75a.75.75 0 0 0 0 1.5zm0 4.5H7.5a.75.75 0 0 0 0-1.5H3.75a.75.75 0 0 0 0 1.5zm0 4.5H7.5a.75.75 0 0 0 0-1.5H3.75a.75.75 0 0 0 0 1.5zm3.75 3H2.25a.75.75 0 0 1-.75-.75V2.25a.75.75 0 0 1 .75-.75h10.629a.75.75 0 0 1 .53.22l2.871 2.87a.75.75 0 0 1 .219.53v1.63a.75.75 0 0 0 1.5 0V5.121a2.25 2.25 0 0 0-.659-1.59L14.47.659A2.25 2.25 0 0 0 12.88 0H2.25A2.25 2.25 0 0 0 0 2.25v16.5A2.25 2.25 0 0 0 2.25 21H7.5a.75.75 0 0 0 0-1.5z" />
    </svg>
  );
};

export const amenityCard = (text, icon) => {
  return (
    <div className={css.amenity}>
      {icon}
      <h3 className={css.amenityTitle}>{text}</h3>
    </div>
  );
};

const SectionAmenities = (props) => {
  const { intl } = props;
  return (
    <div className={css.sectionAmenities}>
      <h1 className={css.sectionTitle}>
        <FormattedMessage id="SectionAmenities.title" />
      </h1>
      <div className={css.amenities}>
        {amenityCard(
          intl.formatMessage({ id: "SectionAmenities.parking" }),
          <IconParking />
        )}
        {amenityCard(
          intl.formatMessage({ id: "SectionAmenities.wifi" }),
          <IconWiFi />
        )}
        {amenityCard(
          intl.formatMessage({ id: "SectionAmenities.airport" }),
          <IconBus />
        )}
        {amenityCard(
          intl.formatMessage({ id: "SectionAmenities.air" }),
          <IconAirconditioner />
        )}
        {amenityCard(
          intl.formatMessage({ id: "SectionAmenities.parking" }),
          <IconCash />
        )}
        {amenityCard(
          intl.formatMessage({ id: "SectionAmenities.reservations" }),
          <IconReserve />
        )}
      </div>
    </div>
  );
};

export default injectIntl(SectionAmenities);
